import React from "react";
import Aboutv6 from "../aboutv6";
import Booksv1 from "../booksv1";
import Contact from "../contact";
import Footer from "../global/footer";
import Header from "../global/header";
import Herov6 from "../herov6";

const VersionMain = ({ header, footer }) => {
  const { menu } = footer;

  return (
    <>
      <Header header={header} />
      <Herov6 isBg="yes"/>
      <Aboutv6 isBg=""/>
      <Booksv1 isBg="yes"/>
      <Contact isBg=""/>
      <Footer isBg="yes" menu={menu} />
    </>
  );
};

export default VersionMain;
