/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CtaV3 from "../ctav3";
import data from "../../data/footer.json";
import { socialLink } from "../../global";
import {Link as PageLink} from "react-router-dom";

const Footer = ({ menu, isBg }) => {
  const { footer } = data;

  return (
    <>
      <CtaV3 />
      <footer
        className={`footer overflow-hidden ${isBg === "yes" ? "bg-one" : ""}`}
      >
        <div className="container">
          <div className="footer__top m-50px-t m-50px-b">
            <div className="row">
              <div className="col-lg-3 d-flex justify-content-center align-items-center mb-3 mb-lg-0 justify-content-lg-start">
                <a href="/">
                  <h1 className="m-0">Stella Rapp</h1>
                  {/* <img src={siteLogo.logo} alt={siteLogo.alt} /> */}
                </a>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center mb-3 mb-lg-0">
                <ul className="footer__menu">
                  {menu?.map((data, i) => (
                    <li key={i}>
                      <PageLink
                          activeClass="active"
                          className="benefits"
                          to={`${data.link}`}
                          spy={true}
                          smooth={true}
                          duration={500}
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          offset={-60}
                      >
                        {data.title}
                      </PageLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-3 d-flex justify-content-center align-items-center mb-3 mb-lg-0 justify-content-lg-end">
                <ul className="social-icon">
                  {socialLink.instagram === "" ? (
                    ""
                  ) : (
                    <li>
                      <a href={socialLink.instagram}>
                        <img
                          className="img-fluid"
                          src="assets/icons/instagram.svg"
                          alt="icon"
                          width="25"
                          height="25"
                        />
                      </a>
                    </li>
                  )}
                  {socialLink.tiktok === "" ? (
                      ""
                  ) : (
                      <li>
                        <a href={socialLink.tiktok}>
                          <img
                              className="img-fluid"
                              src="assets/icons/tiktok.svg"
                              alt="icon"
                              width="25"
                              height="25"
                          />
                        </a>
                      </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="footer__copyright m-20px-t m-20px-b">
            <div className="row">
              <div className="col-12">
                <p className="m-0 text-center">
                  &copy; {footer.copyright}
                  by <a href="https://bitconcepts.de" target="_blank" rel="noopener noreferrer">{footer.name}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
    // <!-- ========== Footer section End ========== -->
  );
};

export default Footer;
