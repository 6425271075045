import React from "react";
import chapterData from "../data/impressum.json";

const Abstract = ({ isBg }) => {
  const { impressum } = chapterData;

  return (
    // <!-- ========== Chapter section start ========== -->
    <section
      id="impressum"
      className={`section-padding chapter ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container pt-5">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{impressum.subtitle}</span>
              <h2 className="display-6">{impressum.title}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {impressum.chapterItem?.map((data, i) => (
            <div
              key={data.id}
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={(i + 2) * 50}
            >
              <div className="chapter__item h-100 translateEffect1">
                <h3>{data.heading}</h3>
                {data.text?.map((textData, textI) => (
                    <p>{textData}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    // <!-- ========== Chapter section end ========== -->
  );
};

export default Abstract;
