import React from "react";

const Herov6 = ({ isBg }) => {
  return (
    //  <!-- ========== Hero section start ========== -->
    <section
      id="hero"
      className={`hero hero__padding overflow-hidden position-relative ${
        isBg === "yes" ? "bg-one" : ""
      }`}
    >
      <div className="circle x1"></div>
      <div className="circle x2"></div>
      <div className="circle x3"></div>
      <div className="circle x4"></div>
      <div className="circle x5"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="hero__content position-relative">
              <h1 className="display-4 mb-2 text-capitalize">Stella Rapp.</h1>
              <div className="badge-text fs-5 mb-4">
                Buchautorin
              </div>
              <p className="mb-5 fs-5">
                Herzlich Willkommen auf meiner Website. Ich bin Stella. Hier findest du Informationen zu meinen Büchern und mir als Autorin.
              </p>
              <a href="#buecher" className="button button__primary me-3">
                <span>Meine Bücher</span>
              </a>
              <a href="#autor" className="btn__secondary">
                <span>Über Mich</span>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero__author text-center">
              <div className="hero__author--inner3">
                <div className="hero__author--inner3--wrapper">
                  <img
                    width="500"
                    className="img-fluid"
                    src="assets/images/stella_rapp_hero.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Hero section end ========== -->
  );
};

export default Herov6;
