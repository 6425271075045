import React from "react";
import Footer from "../global/footer";
import Header from "../global/header";
import Abstract from "../abstract_datenschutz";

const Datenschutz = ({ header, footer }) => {
  const { menu } = footer;

  return (
    <>
      <Header header={header} />
      <Abstract isBg="yes"/>
      <Footer isBg="yes" menu={menu} />
    </>
  );
};

export default Datenschutz;
