import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./assets/css/margins-paddings.css";
import VersionMain from "./components/pages/version-main";
import Impressum from "./components/pages/impressum";
import Datenschutz from "./components/pages/datenschutz";

import AOS from "aos";
import { useEffect } from "react";
import "./assets/css/aos.css";
import footerData from "./data/footer.json";
import headerData from "./data/header.json";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  const { header } = headerData;
  const { footer } = footerData;
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="section-wrapper">
      <div id="preLoader"></div>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<VersionMain header={header} footer={footer} />} />
          <Route path="/impressum" element={<Impressum header={header} footer={footer} />} />
          <Route path="/datenschutz" element={<Datenschutz header={header} footer={footer} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
